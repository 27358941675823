<template>
  <div :class="$store.state.media.isDesktop == true ? 'balance-Pc' : 'balance-notPc'">
    <div class="top">
      <Row :gutter="48" class-name="content-row">
        <Col span="8" class="ivu-mb">
          <Card class="pf16">
            <div class="infoBox">
              <div class="panel-count">总资产（元）</div>
              <div class="text-muted">{{ info.now_money }}</div>
            </div>
          </Card>
        </Col>
        <Col span="8" class="ivu-mb">
          <Card class="pf16">
            <div class="infoBox">
              <div class="panel-count">累计充值（元）</div>
              <div class="text-muted">{{ info.recharge }}</div>
            </div>
          </Card>
        </Col>
        <Col span="8" class="ivu-mb">
          <Card class="pf16">
            <div class="infoBox">
              <div class="panel-count">累计消费（元）</div>
              <div class="text-muted">{{ info.order_status_sum }}</div>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
    <Tabs :value="tabActive" @on-click="changeTab">
      <TabPane
        v-for="(item, index) in tabList"
        :key="index"
        :label="item.label"
        :name="item.name"
      >
        <div class="list" v-if="balanceList.length != 0">
          <div class="list-item" v-for="bls in balanceList" :key="bls.id">
            <div class="item-top">
              <div class="top-left">{{ bls.title }}</div>
              <div class="top-right income" v-if="bls.pm">+￥{{ bls.number }}</div>
              <div class="top-right expenditure" v-else>-￥{{ bls.number }}</div>
            </div>
            <div class="item-bottom">
              <div class="bottom-left">{{ bls.add_time }}</div>
              <div class="bottom-right">{{ bls.balance }}</div>
            </div>
            <Divider />
          </div>
          <Page
            :total="total"
            :v-model="pageData.page"
            :page-size="pageData.limit"
            show-total
            class="pageBar"
            @on-change="pageChange"
          />
        </div>
        <div class="empty" v-else>暂无收支记录···</div>
      </TabPane>
    </Tabs>
  </div>
</template>

<script setup>
import { balanceApi } from "@/api/personalCenter";
import { ref, reactive, onMounted } from "vue";

const info = reactive({
  now_money: "",
  recharge: "",
  order_status_sum: 0,
});
// 分页器
const total = ref(0);
const pageData = reactive({
  page: 1,
  limit: 15,
});
const tabActive = ref("1");
const tabList = reactive([
  {
    label: "全部明细",
    name: "0",
  },
  {
    label: "收入明细",
    name: "2",
  },
  {
    label: "支出明细",
    name: "1",
  },
]);
const balanceList = reactive([]);
function getList(data) {
  balanceApi(data).then((res) => {
    if (res.status === 200) {
      Object.assign(info, res.data.user);
      //   console.log(info);
      total.value = res.data.count;
      Object.assign(balanceList, res.data.data);
    }else {
      Message.error(res.msg);
    }
  });
}
function changeTab(name) {
  if (name == "") {
    pageData.type = "";
  } else {
    pageData.type = name;
  }
  pageData.page = 1;
  getList(pageData);
}
const pageChange = (e) => {
  //   console.log(e);
  pageData.page = e;
  getList(pageData);
};
onMounted(() => {
  getList(pageData);
});
</script>

<style lang="less" scoped>
.balance-Pc {
  min-height: 700px;
  .top {
    .infoBox {
      font-family: SourceHanSansSC-regular;
      color: #000;
      .panel-count {
        font-size: 16px;
      }
      .text-muted {
        font-size: 36px;
      }
    }
    :deep(.ivu-card-body) {
      padding: 36px !important;
    }

  }
  .list-item {
    padding: 15px;
    .item-top,
    .item-bottom {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 10px 0;
      font-family: SourceHanSansSC-regular;
    }
    .item-top {
      font-size: 16px;
      .top-left {
        color: #000;
        font-weight: 600;
      }
    }

    .item-bottom {
      color: #bbb;
    }
    .expenditure {
      color: red;
    }
    .income {
      color: green;
    }
  }
  .pageBar {
    text-align: right;
    margin-top: 50px;
  }
  .empty {
    font-size: 42px;
    color: #bbb;
    height: 100%;
    margin: 0 auto;
    text-align: center;
  }
  :deep(.ivu-tabs-nav-container) {
      font-size: 16px !important;
    }
}
.balance-notPc {
  min-height: 700px;
  background-color: #fff;
  .top {
    .infoBox {
      font-family: SourceHanSansSC-regular;
      color: #000;
      .panel-count {
        font-size: 16px;
      }
      .text-muted {
        font-size: 36px;
      }
    }
    :deep(.ivu-card-body) {
      padding: 36px !important;
    }
    :deep(.ivu-row) {
      flex-direction: column;
    }
    :deep(.ivu-col-span-8) {
      min-width: 100%;
    }
    .content-row {
      .content-row-div:nth-last-of-type(1) {
        display: flex;
        flex-direction: column;
      }
      .content-botton {
        text-align: center;
        button {
          // text-align: center;
          margin: 0 10px;
        }
      }
    }
  }
  .list-item {
    padding: 15px;
    .item-top {
      width: 100%;
      display: flex;
      flex-direction: column;
      font-size: 16px;
      margin: 10px 0;
      font-family: SourceHanSansSC-regular;
      .top-left {
        color: #000;
        font-weight: 600;
      }
      .top-right{
        text-align: right;
      }
    }
    .item-bottom {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 10px 0;
      font-family: SourceHanSansSC-regular;
      color: #bbb;
    }
    .expenditure {
      color: red;
    }
    .income {
      color: green;
    }
    .pageBar {
      text-align: right;
      margin-top: 50px;
    }
    .empty {
      font-size: 42px;
      color: #bbb;
      height: 100%;
      margin: 0 auto;
      text-align: center;
    }
  }
}
</style>
