<template>
  <div :class="$store.state.media.isDesktop == true ? 'dashboard-Pc' : 'dashboard-notPc'">
    <div class="top">
      <Row :gutter="48" class-name="content-row">
        <Col span="8" class="ivu-mb">
          <Card class="pf16">
            <div class="infoBox">
              <div class="panel-count">
                <span>{{ info.wqf }}</span> 张
              </div>
              <b class="text-muted">未签发</b>
            </div>
          </Card>
        </Col>
        <Col span="8" class="ivu-mb">
          <Card class="pf16">
            <div class="infoBox">
              <div class="panel-count">
                <span>{{ info.yqf }}</span> 张
              </div>
              <b class="text-muted" style="color: #19be6b">已签发</b>
            </div>
          </Card>
        </Col>
        <Col span="8" class="ivu-mb">
          <Card class="pf16">
            <div class="infoBox">
              <div class="panel-count">
                <span>{{ info.ygq }}</span> 张
              </div>
              <b class="text-muted" style="color: #ed4014"> 即将到期</b>
            </div>
          </Card>
        </Col>
      </Row>
      <Row class-name="content-row">
        <Col span="24" class="ivu-mb">
          <Card class="pf16">
            <div class="content-row-div">
              <b class="text-muted">余额</b>
              <b class="panel-count">
                ￥<span>{{ info.yuer }}</span>
              </b>
            </div>
            <div class="content-row-div">
              <Button type="primary" @click="recharge">预存</Button>
              <b class="text"
                >因通道需收取2%~6%手续费, 若您需要提现, 请联系客服经理核算费用处理</b
              >
            </div>
          </Card>
        </Col>
      </Row>
      <Row class-name="content-row">
        <Col span="24" class="ivu-mb">
          <Card class="pf16">
            <div class="content-botton">
              <Button @click="bulkOrdering">批量下单</Button>
              <Button @click="bulkExport">批量导出</Button>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
    <!-- <div class="longOutBtn">
      <Button type="error" @click="longOut">退出登录</Button>
    </div> -->
    <bulkOrderingModal
      @refresh="reFreshInfo"
      ref="bulkOrderingModalRef"
    ></bulkOrderingModal>
    <bulkExportModal ref="bulkExportModalRef"></bulkExportModal>
    <rechargeModal ref="rechargeModalRef" @refresh="reFreshInfo"></rechargeModal>
  </div>
</template>

<script setup>
import { dashboardApi, logoutApi } from "@/api/personalCenter";
import { ref, onMounted, reactive } from "vue";
import { Message } from "view-ui-plus";
import { removeCookies } from "@/libs/util";
import router from "@/router";
import BulkExportModal from "@/components/bulkExportModal";
import BulkOrderingModal from "@/components/bulkOrderingModal";
import RechargeModal from "@/components/rechargeModal";
import { storeToRefs } from "pinia";
import { useLoginStore } from "@/store/loginStatus";

const loginStore = useLoginStore();
const { isLoggedIn } = storeToRefs(loginStore);

let info = reactive({ wqf: 0, ygq: 0, yqf: 0, yuer: "0.00" });
let bulkOrderingModalRef = ref(null);
let bulkExportModalRef = ref(null);
let rechargeModalRef = ref(null);
function getDashboardDetail() {
  dashboardApi().then((res) => {
    if (res.status == 200) {
      Object.assign(info, res.data);
    } else {
      Message.error(res.msg);
    }
  });
}
// const longOut = () => {
//   logoutApi()
//     .then((res) => {
//       removeCookies("token");
//       removeCookies("expires_time");
//       removeCookies("is_agent");
//       router.replace({ path: "/" });
//       Message.success("退出成功");
//       localStorage.clear()
//       isLoggedIn.value = false
//     })
//     .catch((res) => {
//       Message.error(res.msg || "退出成功");
//     });
// };
const reFreshInfo = () => {
  getDashboardDetail();
};
const bulkOrdering = () => {
  bulkOrderingModalRef.value.openModal();
};
const bulkExport = () => {
  bulkExportModalRef.value.openModal();
};
const recharge = () => {
  rechargeModalRef.value.openModal();
};
onMounted(getDashboardDetail);
</script>

<style lang="less" scoped>
.dashboard-Pc {
  min-height: 700px;

  .infoBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    // height: 170px;
    // line-height: 170px;
    .panel-count {
      color: #0b1e2e;

      span {
        font-size: 28px;
      }
    }
  }

  :deep(.ivu-card-body) {
    padding: 26px !important;
  }

  .text-muted {
    font-size: 14x;
  }

  .content-row {
    .content-row-div {
      padding: 10px;

      .panel-count {
        span {
          color: #047afe;
          font-family: SourceHanSansSC-regular;
          font-size: 14px;
        }
      }

      button {
        margin-right: 45px;
        height: 38px;
        width: 114px;
      }

      .text {
        font-size: 14px;
      }
    }

    margin-bottom: 25px;
  }

  .content-botton {
    // text-align: center;
    button {
      // text-align: center;
      margin: 0 10px;
    }
  }

  .longOutBtn {
    text-align: right;

    button {
      height: 40px;
      width: 150px;
    }
  }
}

.dashboard-notPc {
  min-height: 700px;

  .infoBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    // height: 170px;
    // line-height: 170px;
    .panel-count {
      color: #0b1e2e;

      span {
        font-size: 28px;
      }
    }
  }

  :deep(.ivu-row) {
    flex-direction: column;
  }

  :deep(.ivu-card-body) {
    padding: 26px !important;
  }

  :deep(.ivu-col-span-8) {
    min-width: 100%;
  }

  :deep(.ivu-modal) {
    top: 10px !important;
  }

  .text-muted {
    font-size: 14x;
  }

  .content-row {
    margin-bottom: 25px;

    .content-row-div {
      padding: 10px;

      .panel-count {
        span {
          color: #047afe;
          font-family: SourceHanSansSC-regular;
          font-size: 14px;
        }
      }

      button {
        margin-right: 45px;
        height: 38px;
        width: 114px;
      }

      .text {
        font-size: 14px;
        margin-top: 10px;
      }
    }

    .content-row-div:nth-last-of-type(1) {
      display: flex;
      flex-direction: column;
    }

    .content-botton {
      text-align: center;

      button {
        // text-align: center;
        margin: 0 10px;
      }
    }
  }

  .longOutBtn {
    text-align: center;

    button {
      height: 40px;
      width: 150px;
    }
  }
}
</style>
