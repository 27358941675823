<template>
  <div :class="$store.state.media.isDesktop == true ? 'product-Pc' : 'product-notPc'">
    <div class="routerBox">
      <Breadcrumb separator=">">
        <BreadcrumbItem key="1" to="/" class="breadItem"> 首页 </BreadcrumbItem>
        <BreadcrumbItem key="2" @click="allBrand" class="breadItem">
          全部品牌
        </BreadcrumbItem>
      </Breadcrumb>
    </div>
    <div class="content">
      <div class="brandTabs">
        <Tabs v-model="brandActive" @on-click="brandTabsClick">
          <TabPane label="全部品牌" name="0"></TabPane>
          <TabPane
            v-for="brand in brandList.product.brands"
            :key="brand.id"
            :label="brand.name"
            :name="brand.id + ''"
          ></TabPane>
        </Tabs>
      </div>
      <div class="typeTabs">
        <div class="examineTabs">
          <div class="tab-title" v-if="$store.state.media.isDesktop == true">
            按审核要求：
          </div>
          <Tabs v-model="examineActive" @on-click="examineTabsClick">
            <TabPane label="全部" name="0"></TabPane>
            <TabPane
              v-for="sh in brandList.product.sh"
              :key="sh.sh_type"
              :label="sh.name"
              :name="sh.sh_type + ''"
            ></TabPane>
          </Tabs>
        </div>
        <div class="functionTabs">
          <div class="tab-title" v-if="$store.state.media.isDesktop == true">
            按使用功能：
          </div>
          <Tabs v-model="functionActive" @on-click="functionTabsClick">
            <TabPane label="全部" name="0"></TabPane>
            <TabPane
              v-for="gn in brandList.product.gn"
              :key="gn.gn_type"
              :label="gn.name"
              :name="gn.gn_type + ''"
            ></TabPane>
          </Tabs>
        </div>
      </div>
    </div>
    <div class="List">
      <product-card :List="productList"></product-card>
    </div>
    <!-- <div class="page">
      <Page
        :total="total"
        v-model="getData.page"
        :page-size="getData.limit"
        size="small"
        show-elevator
        show-total
        show-sizer
        @on-change="pageChange"
        @on-page-size-change="pageSizeChange"
      />
    </div> -->
  </div>
</template>

<script setup>
import { productListApi, getProductMenuIdApi } from "@/api/product";
import { homeBrandSubmenuApi } from "@/api/home";
import ProductCard from "@/components/productCard.vue";
import {
  ref,
  reactive,
  onMounted,
  watchEffect,
  onBeforeUnmount,
  defineProps,
  watch,
  getCurrentInstance,
  nextTick,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import { logoutApi } from "@/api/personalCenter";
import { log } from "mathjs";
const metaTags = getInitialMetaTags();
let router = useRouter();
let route = useRoute();
const brand_id = route.params.brand_id;
const sh_type = route.params.sh_type;
const gn_type = route.params.gn_type;
const props = defineProps(["slug"]);
const { proxy } = getCurrentInstance(); // 获取组件实例

let brandActive = ref("");
let examineActive = ref("");
let functionActive = ref("");
let brandList = reactive({
  product: [],
});
let getData = reactive({
  brand_id: "",
  sh_type: "",
  gn_type: "",
  // page: 1, 
  // limit: 20,
});
let total = ref(0);
const getbrandList = () => {
  homeBrandSubmenuApi().then((res) => {
    if (res.status == 200) {
      Object.assign(brandList.product, res.data);
    } else {
      Message.error(res.msg);
    }
  });
};
let productList = reactive([]);
function getList(data) {
  productListApi(data).then((res) => {
    if (res.status == 200) {
      productList.length = 0; // 清空数组
      // proxy.$translate.execute();
      total.value = res.data.count;
      productList.push(...res.data.list); // 添加新数据到数组
    }
  });
}
// 按品牌
const brandTabsClick = (e) => {
  brandActive.value = e;
  if (e == 0) {
    e = "";
  }
  getData.brand_id = e;
  getList(getData);
};
// 按审核要求
const examineTabsClick = (e) => {
  examineActive.value = e;
  if (e == 0) {
    e = "";
  }
  getData.sh_type = e;
  getList(getData);
};
// 按功能
const functionTabsClick = (e) => {
  functionActive.value = e;
  if (e == 0) {
    e = "";
  }
  getData.gn_type = e;
  getList(getData);
};
// 顶部面包屑-全部品牌
function allBrand() {
  brandTabsClick("0");
  // document.title = '产品选购'
}
function getInitialMetaTags() {
  const title = document.title;
  // const keywords = getMetaContent("keywords");
  // const description = getMetaContent("description");

  // return { title, keywords, description };
  return { title };
}
const getProductMenuId = (data) => {
  getProductMenuIdApi(data).then((res) => {
    if (res.status == 200) {
      if (res.data.brand_id) {
        getData.brand_id = res.data.brand_id;
        brandActive.value = res.data.brand_id.toString();
        scrollToActiveTab(".brandTabs");
      }
      if (res.data.sh_type) {
        getData.sh_type = res.data.sh_type;
        examineActive.value = res.data.sh_type.toString();
        scrollToActiveTab(".examineTabs");
      }
      if (res.data.gn_type) {
        getData.gn_type = res.data.gn_type;
        functionActive.value = res.data.gn_type.toString();
        scrollToActiveTab(".functionTabs");
      }
      getList(getData);
      document.title = res.data.slug + "SSL证书购买代理";
    } else if (res.status == 400) {
      router.replace({ path: "/404" });
    }
  });
};
// 通用滚动方法
const scrollToActiveTab = async (tabContainerClass) => {
  await nextTick();
  setTimeout(() => {
    // 获取滚动容器和选中标签
    const scrollContainer = document.querySelector(
      `${tabContainerClass} .ivu-tabs-nav-scroll`
    );
    const activeTab = document.querySelector(`${tabContainerClass} .ivu-tabs-tab-active`);

    if (!scrollContainer || !activeTab) {
      console.error("元素未找到:", { tabContainerClass });
      return;
    }

    // 关键！强制重置滚动位置（破解iOS缓存）
    scrollContainer.scrollLeft = 0;

    // 强制重置滚动位置（解决iOS问题）
    scrollContainer.scrollLeft = 0;
    scrollContainer.offsetHeight; // 触发重绘

    // 使用getBoundingClientRect获取精确位置
    const containerRect = scrollContainer.getBoundingClientRect();
    const tabRect = activeTab.getBoundingClientRect();

    // 计算居中滚动位置（公式优化）
    const scrollTo =
      tabRect.left - containerRect.left - containerRect.width / 2 + tabRect.width / 2;

    // 执行滚动（降级为instant确保生效）
    scrollContainer.scrollTo({
      left: scrollTo,
      behavior: "instant", // 优先使用平滑，不生效则用instant
    });
  }, 150); // 必须150ms以上延迟！
};
// 页码
const pageChange = (e) => {
  getData.page = e
  getList(getData);
};
// 条数
const pageSizeChange = (e) => {
  getData.limit = e
  getList(getData);
};
pageSizeChange;
watch(
  () => route.params.slug,
  (newVal, oldVal) => {
    if (newVal) {
      getbrandList();
      if (newVal == "all") {
        brandTabsClick("0");
        document.title = "产品选购";
      } else {
        getProductMenuId({ slug: newVal });
      }
    }
  },
  { immediate: true }
);
// 全部
watchEffect(() => {
  if (brandList.product != {} && brandList.product.brands != []) {
    if (brandActive.value != 0) {
      if (brandList.product.brands) {
        brandList.product.brands.map((item, index) => {
          if (brandActive.value == item.id) {
            document.title = item.name + "SSL证书购买代理";
          }
        });
      }
    } else {
      document.title = metaTags.title;
    }
  }
  {
    immediate: true;
  }
});
// 按审核
watchEffect(() => {
  if (brandList.product != {} && brandList.product.sh_type != []) {
    if (examineActive.value != 0) {
      if (brandList.product.sh) {
        brandList.product.sh.map((item, index) => {
          if (examineActive.value == item.sh_type) {
            document.title = item.name + "SSL证书购买代理";
          }
        });
      }
    } else {
      document.title = metaTags.title;
    }
  }
});
// 按功能
watchEffect(() => {
  if (brandList.product != {} && brandList.product.gn_type != []) {
    if (functionActive.value != 0) {
      if (brandList.product.gn) {
        brandList.product.gn.map((item, index) => {
          if (functionActive.value == item.gn_type) {
            document.title = item.name + "SSL证书购买代理";
          }
        });
      }
    } else {
      document.title = metaTags.title;
    }
  }
});
onMounted(async () => {
  await Promise.all([getbrandList()]);
  // 依次触发滚动（按需调整顺序）
  await scrollToActiveTab(".brandTabs");
  await scrollToActiveTab(".examineTabs");
  await scrollToActiveTab(".functionTabs");

  getList(getData);
});
onBeforeUnmount(() => {
  document.title = metaTags.title;
});
</script>

<style lang="less" scoped>
.product-Pc {
  padding: 25px 5% 40px;
  min-height: 700px;
  .routerBox {
    margin-bottom: 15px;
  }
  .content {
    .brandTabs {
      background-color: #fff;
      padding: 0 15px;
      margin-bottom: 25px;
    }
    .typeTabs {
      padding: 20px 15px;
      background-color: #fff;
    }
    :deep(.ivu-tabs-bar) {
      border: none !important;
      margin-bottom: 0;
    }
    :deep(.ivu-tabs-tab) {
      padding: 15px 20px;
    }
    :deep(.ivu-tabs-nav-container) {
      font-size: 16px !important;
    }
    :deep(.ivu-tabs-ink-bar) {
      height: 3px !important;
    }
    :deep(.ivu-tabs-nav-prev),
    :deep(.ivu-tabs-nav-next) {
      line-height: 54px;
    }
    .typeTabs {
      .examineTabs,
      .functionTabs {
        display: flex;
        flex-direction: row;
        .tab-title {
          padding: 15px 20px;
          font-size: 16px;
          color: #000;
        }
        :deep(.ivu-tabs-ink-bar) {
          height: 0px !important;
        }
      }
      .examineTabs {
        border-bottom: 1px dashed #cecece;
      }
    }
  }
  .List {
    margin-top: 40px;
  }
}
.product-notPc {
  padding: 25px 5% 40px;
  min-height: 700px;
  .routerBox {
    margin-bottom: 15px;
  }
  .content {
    .brandTabs {
      padding: 0 15px;
      margin-bottom: 25px;
    }
    .typeTabs {
      padding: 20px 15px;
    }
    .brandTabs,
    .typeTabs {
      background-color: #fff;
    }
    :deep(.ivu-tabs-bar) {
      border: none !important;
      margin-bottom: 0;
    }
    :deep(.ivu-tabs-tab) {
      padding: 15px 20px;
    }
    :deep(.ivu-tabs-nav-container) {
      font-size: 16px !important;
    }
    :deep(.ivu-tabs-ink-bar) {
      height: 3px !important;
    }
    :deep(.ivu-tabs-nav-scrollable) {
      padding: 0 10px;
    }
    :deep(.ivu-tabs-nav-prev) {
      display: none !important;
    }
    :deep(.ivu-tabs-nav-next) {
      display: none !important;
    }
    // 品牌Tabs
    .brandTabs {
      :deep(.ivu-tabs-nav) {
        transform: none !important;
        transition: none !important;
        display: inline-flex; // 防止标签换行
      }
      :deep(.ivu-tabs-nav-scroll) {
        overflow-x: auto !important;
        scroll-behavior: smooth;
        // width: 100vw !important; // 强制视口宽度
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
    .examineTabs {
      :deep(.ivu-tabs-nav) {
        transform: none !important;
        transition: none !important;
        display: inline-flex; // 防止标签换行
      }
      :deep(.ivu-tabs-nav-scroll) {
        overflow-x: auto !important;
        scroll-behavior: smooth;
        // width: 100vw !important; // 强制视口宽度
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
    .functionTabs {
      :deep(.ivu-tabs-nav) {
        transform: none !important;
        transition: none !important;
        display: inline-flex; // 防止标签换行
      }
      :deep(.ivu-tabs-nav-scroll) {
        overflow-x: auto !important;
        scroll-behavior: smooth;
        // width: 100vw !important; // 强制视口宽度
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
    .typeTabs {
      .examineTabs,
      .functionTabs {
        display: flex;
        flex-direction: row;
        .tab-title {
          padding: 15px 20px;
          font-size: 16px;
          color: #000;
        }
        :deep(.ivu-tabs-ink-bar) {
          height: 0px !important;
        }
      }
      .examineTabs {
        border-bottom: 1px dashed #cecece;
      }
    }
  }
}
.page {
  float: right;
}
</style>
