<template>
  <div class="downloadCertChain">
    <div class="titleBox">
      <div class="title">SSL证书链下载</div>
      <div class="typeTips">下载完整的证书链</div>
    </div>
    <div
      class="content"
      :style="
        $store.state.media.isDesktop == true
          ? 'flex-direction: row'
          : 'flex-direction: column'
      "
    >
      <Input
        v-model="domain"
        size="large"
        :style="
          $store.state.media.isDesktop == true
            ? 'width:50%'
            : 'width:100%;margin-bottom:10px'
        "
        placeholder="请输入域名或IP：（ 例如：rjxssl.com:443）"
      />
      <Button type="primary" :loading="loading" size="large" @click="submit"
        >获取证书链</Button
      >
    </div>
    <div
      class="isLoading"
      v-if="loadingMsg != ''"
      :style="loadingMsg == '证书链获取中...' ? 'color:#1374e0' : 'color:red'"
    >
      {{ loadingMsg }}
    </div>
    <div class="result" v-if="isBuild == true">
      <div class="result-title">搜索结果</div>
      <Divider />
      <div class="result-item">
        <div class="certType">
          <div class="type_name">证书链</div>
        </div>
        <Button type="primary" class="downLoad" @click="exoprtCert(csrFile)"
          >点击下载</Button
        >
      </div>
      <pre class="buildPfx" @click="copy(result.chain)">{{ result.chain }}</pre>
    </div>

    <div class="tips">
      <div class="tips-title">SSL证书链下载常见问题</div>
      <div class="tips-content">
        <div class="question-item" style="margin: 20px 0 5px">
          <div class="question">Q</div>
          <div class="item-msg">
            <strong>SSL证书链是什么？</strong>
          </div>
        </div>
        <div class="question-item">
          <div class="answer">A</div>
          <div class="item-msg">
            <ul>
              <li>
                一条完整的证书信任链是由根证书，中间证书，用户服务器证书组成的。只有当整个证书信任链上的各个证书都有效时，浏览器才会认定当前颁发给服务器的证书是有效和受信任的。证书链文件就是除了服务器证书以外的中间证书和根证书组成的证书文件。证书链文件是证书部署和验证证书是否可信环节中最重要的组成部分。
              </li>
            </ul>
          </div>
        </div>
        <div class="question-item" style="margin: 20px 0 5px">
          <div class="question">Q</div>
          <div class="item-msg">
            <strong>SSL证书链不完整会有什么影响？ </strong>
          </div>
        </div>
        <div class="question-item">
          <div class="answer">A</div>
          <div class="item-msg">
            <ul>
              <li>
                证书链不完整将导致网站部署SSL证书后仍被提示不受信任。浏览器、手机设备、应用程序等因无法验证SSL证书的有效性和真实性，从而给出风险提示。所以，为了兼容所有客户端，服务器需要安装完整的证书链。
              </li>
            </ul>
          </div>
        </div>
        <div class="question-item" style="margin: 20px 0 5px">
          <div class="question">Q</div>
          <div class="item-msg">
            <strong>SSL证书链提示证书链不完整该如何处理？</strong>
          </div>
        </div>
        <div class="question-item">
          <div class="answer">A</div>
          <div class="item-msg">
            <ul>
              <li>
                如果配置SSL证书提示证书链不完整，只需要使用证书链下载工具，输入域名即可获取到该证书链，将证书链重新配置到服务器上就好了。
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, watch, onBeforeUnmount } from "vue";
import { downloadCertChainApi } from "@/api/tools";
import { Message, Copy } from "view-ui-plus";

let domain = ref("");
let loading = ref(false);
let loadingMsg = ref("");
let csrFile = ref("");
let result = reactive({});
let isBuild = ref(false);
const submit = () => {
  loading.value = true;
  if (domain.value != "") {
    loadingMsg.value = "证书链获取中...";
    downloadCertChainApi({ domain: domain.value })
      .then((res) => {
        if (res.status == 200) {
          // console.log(res.data);
          Object.assign(result, res.data);
          csrFile.value = res.data.path;
          Message.success("获取成功");
          loadingMsg.value = "";
          loading.value = false;
          isBuild.value = true;
        } else {
          loading.value = false;
          isBuild.value = false;
          Message.error(res.msg);
        }
      })
      .catch((err) => {
        loading.value = false;
        isBuild.value = false;
        loadingMsg.value = err.msg;
      });
  } else {
    loadingMsg.value = "*域名不能为空";
    loading.value = false;
  }
};
const copy = (v) => {
  Copy({
    text: v,
  });
};
// 导出证书
const exoprtCert = (path) => {
  window.location.href = `https://api.rjxssl.com/api/download?path=${path}`;
};
onMounted(() => {
  document.title = "SSL证书链下载";
});
</script>
<style lang="less" scoped>
.downloadCertChain {
  padding: 25px 5% 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  .titleBox {
    margin-bottom: 15px;
    .title {
      font-size: 36px;
      color: #000;
    }
    .typeTips {
      text-align: center;
      span {
        color: #1374e0;
      }
    }
  }
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    :deep(.ivu-btn) {
      width: 150px;
    }
  }
  .isLoading {
    width: 100%;
    text-align: center;
    font-size: 18px;
    color: #1374e0;
    margin-top: 20px;
  }
  .result {
    width: 100%;
    .result-title {
      font-size: 18px;
    }
    .result-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .certType {
        .type_name {
          font-size: 18px;
        }
      }
    }
    .buildPfx {
      width: 100%;
      height: 450px;
      overflow: auto;
      background-color: #f4f5f9;
      padding: 14px 10px;
      border-radius: 5px;
    }
  }
  .tips {
    width: 100%;
    padding: 40px 25px;
    margin-top: 30px;
    background-color: #f4f5f9;
    .tips-title {
      font-size: 18px;
      color: #1374e0;
      margin-bottom: 25px;
    }
    .tips-content {
      .question-item {
        display: flex;
        .question {
          min-width: 20px;
          height: 20px;
          background-color: #ffffff;
          border: solid 1px #cccccc;
          color: #1374e0;
          margin-right: 15px;
          text-align: center;
          line-height: 19px;
        }
        .answer {
          min-width: 20px;
          height: 20px;
          background-color: #1374e0;
          color: #ffffff;
          margin-right: 15px;
          text-align: center;
          line-height: 19px;
        }
        ul {
          list-style-type: none;
        }
      }
    }
  }
}
</style>
