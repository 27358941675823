<template>
  <div>
    <Modal
      v-model="showRechargeModal"
      :closable="true"
      :mask-closable="false"
      :loading="true"
      title="预存"
      :footer-hide="store.state.media.isDesktop == true ? true : false"
      @on-visible-change="modalChange"
    >
      <Form class="form" ref="chargeForm" :model="chargeData" :label-width="100">
        <Row :gutter="24" type="flex">
          <Col span="24">
            <FormItem label="充值金额" prop="amount">
              <Input
                type="number"
                :number="true"
                v-model="chargeData.amount"
                placeholder="0.01~10000"
                size="large"
              />
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="24" type="flex">
          <Col span="24">
            <FormItem label="充值方式" prop="pay_type">
              <RadioGroup v-model="chargeData.pay_type" @on-change="currentPay">
                <Radio label="wx" :disabled="wxDis">
                  <span>微信</span>
                </Radio>
                <Radio label="zfb" :disabled="zfbDis">
                  <span>支付宝</span>
                </Radio>
              </RadioGroup>
            </FormItem>
          </Col>
        </Row>
        <div class="payQrCode" v-if="showPayCode == true">
          <div
            class="codeBox"
            v-if="chargeData.pay_type == 'wx' && store.state.media.isDesktop == true"
          >
            <div class="payType">
              <div class="typeIcon">
                <img src="@/assets/images/WeChatPay.png" alt="" />
              </div>
              <div class="typeName">微信支付</div>
            </div>
            <div :class="chargeData.pay_type == 'wx' ? 'greenBox' : 'blueBox'">
              <div class="code">
                <img
                  id="qr-code"
                  :src="codeLinks"
                  v-if="codeLinks != ''"
                  alt="支付二维码"
                />
                <Spin fix v-else>
                  <Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
                  <div>Loading</div>
                </Spin>
              </div>
            </div>
          </div>
          <div
            class="codeBox"
            v-if="chargeData.pay_type == 'zfb' && store.state.media.isDesktop == true"
          >
            <div class="payType">
              <div class="typeIcon">
                <img src="@/assets/images/ipayIcon.png" alt="" />
              </div>
              <div class="typeName">支付宝支付</div>
            </div>
            <div :class="chargeData.pay_type == 'wx' ? 'greenBox' : 'blueBox'">
              <div class="code">
                <img
                  id="qr-code"
                  :src="codeLinks"
                  v-if="codeLinks != ''"
                  alt="支付二维码"
                />
                <Spin fix v-else>
                  <Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
                  <div>Loading</div>
                </Spin>
              </div>
            </div>
          </div>
        </div>
      </Form>
      <template #footer>
        <div class="footerBtn">
          <Button type="text" size="large" @click="cancel">取消</Button>
          <Button type="primary" size="large" :loading="loading" @click="submitForm"
            >预存</Button
          >
        </div>
      </template>
    </Modal>
  </div>
</template>

<script setup>
import { rechargeApi, checkYeStatusApi } from "@/api/personalCenter";
import { payCodeApi } from "@/api/order";
import { ref, onMounted, reactive, getCurrentInstance, watch,onUnmounted } from "vue";
import { useStore } from "vuex";
const { proxy } = getCurrentInstance(); // 获取组件实例

import { Message } from "view-ui-plus";
const store = useStore();
let showRechargeModal = ref(false);
let chargeData = reactive({
  amount: null,
  pay_type: "",
  from: "",
});
let showPayCode = ref(false);
let order_id = ref("");
let codeLinks = ref("");
let loading = ref(false);
const emit = defineEmits(["refresh"]);
let wxDis = ref(false);
let zfbDis = ref(false);
let redirect = ref("");
// 获取支付二维码
const payCode = (data) => {
  payCodeApi(data).then((res) => {
    // console.log(res);
    if (res.status == 200) {
      codeLinks.value = res.data.code_path;
    } else {
      Message.error(res.msg);
    }
  });
};
// 当前支付方式
const currentPay = (pay_type) => {
  stopPolling();
  chargeData.amount = chargeData.amount;
  chargeData.pay_type = pay_type;
  codeLinks.value = "";
  if (chargeData.pay_type == "wx" && chargeData.amount > 0) {
    zfbDis.value = true;
    chargeData.from = store.state.media.isDesktop == true ? "pc" : "weixinh5";
    rechargeApi(chargeData).then((res) => {
      if (res.status == 200) {
        zfbDis.value = false;
        order_id.value = res.data.result.order_id;
        if (chargeData.from == "pc") {
          payCode({ text: res.data.result.jsConfig });
          submitForm();
        } else {
          showPayCode.value = false;
          redirect.value =
            res.data.result.jsConfig.mweb_url +
            "&redirect_url=https://www.rjxssl.com/balance";
        }
      }
    });
  }
  // 支付宝支付
  if (chargeData.pay_type == "zfb" && chargeData.amount > 0) {
    wxDis.value = true;
    chargeData.from = store.state.media.isDesktop == true ? "zfbpc" : "zfbh5";
    rechargeApi(chargeData).then((res) => {
      if (res.status == 200) {
        wxDis.value = false;
        order_id.value = res.data.result.order_id;
        if (res.data.result.jsConfig) {
          if (chargeData.from == "zfbpc") {
            payCode({ text: res.data.result.jsConfig.qrCode });
            submitForm();
          } else {
            showPayCode.value = false;
            redirect.value = res.data.result.jsConfig;
          }
        }
      } else {
        Message.error(res.msg);
      }
    });
  }
};

let pollingInterval;
// 扫码支付结果轮询
const submitForm = () => {
  if (store.state.media.isDesktop == true) {
    loading.value = true;
    pollingInterval = setInterval(() => {
      checkYeStatusApi({ order_id: order_id.value })
        .then((res) => {
          if (res.status == 200) {
            if (res.data.status == true) {
              Message.success("预存成功");
              emit("refresh");
              showRechargeModal.value = false;
              loading.value = false;
              setTimeout(() => {
                stopPolling();
              }, 1000);
            }
          } else {
            loading.value = false;
            stopPolling();
            Message.error(res.msg);
          }
        })
        .catch((err) => {
          loading.value = false;
          Message.error("预存失败");
          setTimeout(() => {
            stopPolling();
          }, 1000);
        });
    }, 3000);
  } else {
    if (chargeData.from == "weixinh5") {
      window.location.href = redirect.value;
    } else {
      document.write(redirect.value);
    }
    stopPolling();
  }
};
const stopPolling = () => {
  clearInterval(pollingInterval);
};

const openModal = () => {
  proxy.$refs.chargeForm.resetFields();
  showRechargeModal.value = true;
  // currentPay("zfb");
  showPayCode.value = false;
  chargeData.pay_type = "zfb";
};
const cancel = () => {
  proxy.$refs.chargeForm.resetFields();
  showPayCode.value = false;
  showRechargeModal.value = false;
  chargeData.pay_type = "";
  loading.value = false;
};
// 处理关闭
const modalChange = (e) => {
  if (e == false) {
    cancel();
  }
};
watch(
  () => chargeData.amount,
  (newVal) => {
    if (newVal !== "" && newVal != null) {
      if (newVal > 0) {
        showPayCode.value = true;
        currentPay(chargeData.pay_type);
      } else {
        Message.warning("请填写正确的金额");
        showPayCode.value = false;
      }
    }
  }
);
defineOptions({
  name: "RechargeModal",
});
defineExpose({
  openModal,
  cancel,
  stopPolling,
  chargeForm: proxy.$refs.chargeForm,
});
onUnmounted(() => {
  stopPolling();
});
</script>
<style lang="less" scoped>
.payQrCode {
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  .codeBox {
    width: 350px;
    height: 400px;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
    .payType {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .typeIcon {
        width: 35px;
        height: 35px;
        margin-right: 10px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .typeName {
        font-size: 16px;
        color: #000;
        font-weight: 400;
      }
    }
    .greenBox,
    .blueBox {
      border-radius: 10px;
      width: 200px;
      height: 200px;
      margin: 30px 0 30px;
      .code {
        width: 180px;
        height: 180px;
        position: relative;
        img {
          width: 100%;
          height: 100%;
        }
        .demo-spin-icon-load {
          animation: ani-demo-spin 1s linear infinite;
        }
      }
    }
    .greenBox {
      border: 10px solid #43c93e;
    }
    .blueBox {
      border: 10px solid #06b4fd;
    }
  }
}
</style>
