<template>
  <div class="decodeCsr">
    <div class="titleBox">
      <div class="title">CSR文件在线验证工具</div>
      <div class="typeTips">解码你的CSR并确保信息正确无误</div>
    </div>

    <div class="result">
      <div class="result-item">
        <div class="certType">
          <div class="type_name">CSR文件</div>
          <div class="type_msg">请按需选择粘贴代码或上传文件</div>
        </div>
        <Select v-model="decodeData.type" style="width: 100px">
          <Option value="0">粘贴代码</Option>
          <Option value="1">上传文件</Option>
        </Select>
      </div>
      <div class="createBox" v-if="decodeData.type == '0'">
        <div class="type_tips">
          请在下面文本框内粘贴你的CSR文件以"-----BEGIN CERTIFICATE
          REQUEST-----"开头和以"-----END CERTIFICATE
          REQUEST-----"结尾的字符串，点击按钮进行验证。
        </div>
        <Input class="buildPfx" v-model="decodeData.csr" type="textarea" />
      </div>

      <div class="upload" v-if="decodeData.type == '1'">
        <Button type="primary" @click="handleClick('cert')" icon="md-cloud-upload"
          >上传文件</Button
        >
        <div class="fileName">{{ csrFileName }}</div>
        <input type="file" ref="fileInput" @change="fileChange" style="display: none" />
      </div>
      <Button type="primary" :loading="loading" @click="submit">验证CSR文件</Button>
    </div>
    <div class="result" v-if="isBuild == true" style="margin-top: 30px">
      <div class="result-title">验证结果</div>
      <Divider />
      <div class="content">
        <div class="select">
          <div class="select-item">
            <div class="label">域名</div>
            <div class="value">{{ resultData.CN }}</div>
          </div>
          <div class="select-item">
            <div class="label">部门/单位</div>
            <div class="value">{{ resultData.OU }}</div>
          </div>
          <div class="select-item">
            <div class="label">组织/公司名称</div>
            <div class="value">{{ resultData.O }}</div>
          </div>
          <div class="select-item">
            <div class="label">城市</div>
            <div class="value">{{ resultData.L }}</div>
          </div>
          <div class="select-item">
            <div class="label">省份</div>
            <div class="value">{{ resultData.ST }}</div>
          </div>
          <div class="select-item">
            <div class="label">国家</div>
            <div class="value">{{ resultData.C }}</div>
          </div>
          <div class="select-item">
            <div class="label">邮箱</div>
            <div class="value">{{ resultData.emailAddress }}</div>
          </div>
          <div class="select-item">
            <div class="label">加密算法</div>
            <div class="value">{{ resultData.keyAlgorithms }}</div>
          </div>
          <div class="select-item">
            <div class="label">哈希签名算法</div>
            <div class="value">{{ resultData.signAlgoHash }}</div>
          </div>
          <div class="select-item">
            <div class="label">加密位数</div>
            <div class="value">{{ resultData.bits }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="result">
      <div class="result-item" style="margin-top: 40px">
        <div class="certType">
          <div class="type_name">正确CSR文件示范</div>
        </div>
      </div>
      <pre class="buildPfx" @click="copy(result.privateKey)">{{ result.privateKey }}</pre>
    </div>
    <div class="tips">
      <div class="tips-title">CSR文件验证工具的常见问题</div>
      <div class="tips-content">
        <div class="question-item" style="margin: 20px 0 5px">
          <div class="question">Q</div>
          <div class="item-msg">
            <strong>为什么需要验证CSR文件？</strong>
          </div>
        </div>
        <div class="question-item">
          <div class="answer">A</div>
          <div class="item-msg">
            <ul>
              <li>
                解码验证CSR文件的目的在于在提交数字证书前检查用户生成的CSR文件中的信息是否准确无误。如信息没有问题，那么证书将很快顺利签发；如填写有误，证书签发流程受阻，则需更改符合验证要求的信息才能完成签发。
              </li>
            </ul>
          </div>
        </div>
        <div class="question-item" style="margin: 20px 0 5px">
          <div class="question">Q</div>
          <div class="item-msg">
            <strong>生成CSR文件时常见错误有哪些？ </strong>
          </div>
        </div>
        <div class="question-item">
          <div class="answer">A</div>
          <div class="item-msg">
            <ul>
              <li>
                1.用户申请通配符SSL证书时，填写的域名不是通配符域名，遗漏掉星号标识*.。
              </li>
              <li>2.申请企业型SSL证书，即OV/EV SSL证书，公司名称不是注册名称。</li>
            </ul>
          </div>
        </div>
        <div class="question-item" style="margin: 20px 0 5px">
          <div class="question">Q</div>
          <div class="item-msg">
            <strong>使用CSR在线验证工具需要注意什么？</strong>
          </div>
        </div>
        <div class="question-item">
          <div class="answer">A</div>
          <div class="item-msg">
            <ul>
              <li>
                将CSR文件粘贴到空白框中需要以"-----BEGIN CERTIFICATE
                REQUEST-----"开头和以"-----END CERTIFICATE
                REQUEST-----"结尾的字符串，中间不能其他多余的字符或空格。
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, watch, onBeforeUnmount } from "vue";
import { decodeCsrApi } from "@/api/tools";
import { Message, Copy } from "view-ui-plus";

let decodeData = reactive({
  type: "0",
});
let resultData = reactive({});
let result = reactive({
  csr: "",
  privateKey: `-----BEGIN CERTIFICATE REQUEST-----
MIIC8TCCAdkCAQAwgasxCzAJBgNVBAYTAkNOMQ8wDQYDVQQIDAbmtZnmsZ8xDzAN
BgNVBAcMBuadreW3njEzMDEGA1UECgwq5p2t5bee5Li05bmz55Ge5p2w5L+h572R
57uc5oqA5pyv5pyN5Yqh6YOoMQswCQYDVQQLDAJJVDEXMBUGA1UEAwwOd3d3LnJq
eHNzbC5jb20xHzAdBgkqhkiG9w0BCQEWEGFkbWluQHJqeHNzbC5jb20wggEiMA0G
CSqGSIb3DQEBAQUAA4IBDwAwggEKAoIBAQCszrdRt2sOlViI3W1rLROcZW1NSwev
iaNLiyZOm6TlwrWCm4IatvrU/yfTbHDZKD/uAF/Z/OIwBNyjbhONHEO1j4y01eHF
m3CG2RPG0lWpIjEhphHVMXm1fyya5t3NHGjSLXtJTyPdjWsltub7ISu+mgLr8zQY
2qOLzOTmvEz5lv/hGfdORa+bMLvkOOMgZ00rgCx/k/K92ZFB+x2v1m98kayyBnhy
lRBZX5CFiQgPiKbQ6yJc73qO1mucrY6AaAXGPNJpzbsMnBucRbXt5Vg0uiz+oLBq
aB2zOiUn7KOCeRNTKUDLc/hueGRkxYqGHmYmX1KbnHtrj8XI+fAf32IHAgMBAAGg
ADANBgkqhkiG9w0BAQsFAAOCAQEAQlZotBW1mnexY4sXg+NaZAziR5FjLxtoX5If
irXTqzSytlJnMXdozBx/lTWg0vmJWYDsIGVJD/FjvC5ZNy2AFtzrbhnBDJOmJ93J
HZT8AKdbb5SHAsXTE+cvY+oZoLBnobVTdPpfQ9M0pW8rrU3pBQ2qRtPeR67emCwc
5fjKZ8rGdSIUBbQioMB2VjyOeLAg2fhcUTKmGXWYlAZXa3l8+Js5iJP5K0sjS+bu
uRQVlGKV0kAEbfOLn59j0+H52+D2pr+rMzKd4U2O2EIoZ0vpGSiyliU75x6fR/u3
VmrBPMVz3dbcA+WewGmOof32Jz6PxCq2nWYCIFZYMBS+R06xuw==
-----END CERTIFICATE REQUEST-----`,
});
let csrFileName = ref("请上传CSR证书 ");
let fileInput = ref(null);
let loading = ref(false);
let isBuild = ref(false);
const handleClick = () => {
  fileInput.value.click(); // 触发隐藏的文件输入框
};
const fileChange = (event) => {
  decodeData.csrFile = event.target.files[0];
  csrFileName.value = event.target.files[0].name;
};
const submit = () => {
  decodeCsrApi(decodeData)
    .then((res) => {
      if (res.status == 200) {
        // console.log(res.data);
        Object.assign(resultData, res.data);
        Message.success("验证成功");
        loading.value = false;
        isBuild.value = true;
      } else {
        loading.value = false;
        isBuild.value = false;
        Message.error(res.msg);
      }
    })
    .catch((err) => {
      loading.value = false;
      isBuild.value = false;
      Message.error(err.msg);
    });
};
const copy = (v) => {
  Copy({
    text: v,
  });
};
watch(
  () => decodeData.type,
  (newVal, oldVal) => {
    if (newVal) {
      decodeData.csr = "";
      certFileName.value = "请上传CSR证书"; // 清除证书文件名
    }
  }
);
onMounted(() => {
  document.title = "CSR文件在线验证工具";
});
</script>
<style lang="less" scoped>
.decodeCsr {
  padding: 25px 5% 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  .titleBox {
    margin-bottom: 15px;
    .title {
      font-size: 36px;
      color: #000;
    }
    .typeTips {
      text-align: center;
      span {
        color: #1374e0;
      }
    }
  }

  .result {
    width: 100%;
    .result-title {
      font-size: 18px;
    }
    .result-item {
      display: flex;
      //   justify-content: space-between;
      align-items: center;
      .certType {
        margin-right: 35px;
        .type_name {
          font-size: 20px;
          color: #000;
        }
      }
    }
    .createBox {
      .type_tips {
        font-family: PingFang-SC-Medium;
        font-size: 14px;
        line-height: 24px;
        color: #eb6529;
        margin-bottom: 22px;
      }
    }
    .buildPfx {
      width: 100%;
      height: 450px;
      overflow: auto;
      background-color: #f4f5f9;
      padding: 14px 10px;
      border-radius: 5px;
      margin-bottom: 20px;
      :deep(.ivu-input) {
        height: 100%;
        background-color: #f4f5f9;
        border: none;
      }
    }
    .upload {
      margin-bottom: 20px;
      padding: 20px 0;
      text-align: center;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .fileName {
        margin-left: 30px;
      }
    }
    .content {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: left;
      padding: 5px 0;
      .content-title {
        font-family: PingFang-SC-Medium;
        font-size: 18px;
        color: #111111;
        margin-bottom: 10px;
      }
      .select {
        .select-item {
          height: 40px;
          line-height: 40px;
          background-color: #f4f5f9;
          display: flex;

          .label {
            border-right: 1px solid #e6e6e6;
            border-bottom: 1px solid #e6e6e6;
            padding: 0 20px;
            text-align: center;
            width: 60%;
          }
          .value {
            width: 100%;
            padding: 0 20px;
            // border-right: 1px solid #e6e6e6;
            border-bottom: 1px solid #e6e6e6;
          }
        }
      }
    }
  }
  .tips {
    width: 100%;
    padding: 40px 25px;
    margin-top: 30px;
    background-color: #f4f5f9;
    .tips-title {
      font-size: 18px;
      color: #1374e0;
      margin-bottom: 25px;
    }
    .tips-content {
      .question-item {
        display: flex;
        .question {
          min-width: 20px;
          height: 20px;
          background-color: #ffffff;
          border: solid 1px #cccccc;
          color: #1374e0;
          margin-right: 15px;
          text-align: center;
          line-height: 19px;
        }
        .answer {
          min-width: 20px;
          height: 20px;
          background-color: #1374e0;
          color: #ffffff;
          margin-right: 15px;
          text-align: center;
          line-height: 19px;
        }
        ul {
          list-style-type: none;
        }
      }
    }
  }
}
</style>
