<template>
  <Drawer
    :z-index="1006"
    :closable="true"
    v-model="isShowDrawer"
    :mask-closable="false"
    :mask-style="{
      'z-index': '1003',
    }"
    class-name="drawerBox"
    :width="drawerWidth"
    @on-close="closeDrawer"
  >
    <template #header>
      <div style="font-size: 16px; padding: 10px 0; color: #000">
        {{ drawerTitle }}
      </div>
    </template>
    <Form
      :model="placeOrderFormData"
      :rules="ruleInline"
      ref="placeOrderForm"
      label-position="top"
    >
      <FormItem
        :label="
          purchasePeriod.default_protect_domain > 0 && purchasePeriod.support_san == 1
            ? '购买年限      （自带' + purchasePeriod.default_protect_domain + '个SAN）'
            : '购买年限'
        "
        prop="periods"
      >
        <div class="periods first">
          <Select
            v-model="placeOrderFormData.periods"
            style="width: 250px"
            @on-select="periodsSelect"
          >
            <Option
              v-for="(ord, idx) in periods_price_txt"
              :value="ord.name"
              :key="idx"
              :label="ord.name"
            >
            </Option>
          </Select>
          <!-- <div class="price" v-if="periodsPrice > 0">{{ periodsPrice }}元</div> -->
        </div>
      </FormItem>
      <FormItem label="优惠券码" prop="coupon">
        <Input
          type="text"
          v-model="placeOrderFormData.coupon"
          placeholder="请输入优惠券码"
          style="width: '250px'"
        />
      </FormItem>
      <!-- 
          混合证书：显示三个   ssl_type == 3
          IP证书并且支持追加域名: 显示其他IP SAN ssl_type == 1
          通配符证书并且支持追加域名: 显示其他通配符 ssl_type == 2
          普通域名并且支持追加域名: 显示其他域名 ssl_type == 0
          -->
      <FormItem v-if="isShowOrdinary == true" prop="ordinary_san_num">
        <template #label>
          <div class="">单域名</div>
        </template>
        <div
          class="periods"
          v-if="purchasePeriod.san_price.ordinary[purchasePeriod.default] != -1"
        >
          <InputNumber
            min="0"
            :max="max_domain"
            v-if="purchasePeriod.ssl_type == 3"
            v-model="placeOrderFormData.ordinary_san_num"
            controls-outside
          />
          <InputNumber
            :min="0"
            :max="3000"
            v-else
            v-model="placeOrderFormData.ordinary_san_num"
            controls-outside
          />
          <div class="priceBox" v-if="placeOrderFormData.ordinary_san_num > 0">
            <div class="label">每个域名为{{ ordinary_san_num }}/年</div>
          </div>
        </div>
      </FormItem>
      <FormItem label="IP" v-if="isShowIp == true" prop="ip_san_num">
        <div
          class="periods"
          v-if="purchasePeriod.san_price.ip[purchasePeriod.default] != -1"
        >
          <InputNumber
            :min="0"
            v-model="placeOrderFormData.ip_san_num"
            controls-outside
          />
          <div class="priceBox" v-if="placeOrderFormData.ip_san_num > 0">
            <div class="label">每个域名为{{ ip_san_num }}/年</div>
          </div>
        </div>
      </FormItem>
      <FormItem label="通配符" v-if="isShowSan == true" prop="wildcard_san_num">
        <div
          class="periods"
          v-if="purchasePeriod.san_price.wildcard[purchasePeriod.default] != -1"
        >
          <InputNumber
            min="0"
            v-model="placeOrderFormData.wildcard_san_num"
            controls-outside
          />
          <div class="priceBox" v-if="placeOrderFormData.wildcard_san_num > 0">
            <div class="label">每个域名为{{ wildcard_san_num }}/年</div>
          </div>
        </div>
      </FormItem>
    </Form>
    <div class="drawer-footer">
      <div class="sum">
        小计：<span>{{ sum }}</span
        >元
      </div>
      <Button
        type="primary"
        style="width: 100%; height: 40px; font-size: 16px"
        @click="drawerCheckBtn"
        :loading="loading"
        >立刻下单</Button
      >
    </div>
  </Drawer>
</template>

<script setup>
import { getProductDetailsApi, orderPlaceApi } from "@/api/order";
import { calculatePriceApi } from "@/api/product";
import {
  ref,
  reactive,
  onMounted,
  defineOptions,
  watchEffect,
  computed,
  watch,
  getCurrentInstance,
  defineProps,
  onBeforeUnmount,
} from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { typeOf } from "view-ui-plus/src/utils/assist";
import { Message } from "view-ui-plus";
const { proxy } = getCurrentInstance(); // 获取组件实例
// 获取全局管理的 title、keywords 和 description
const metaTags = getInitialMetaTags();
const store = useStore();
let router = useRouter();

let isShowDrawer = ref(false);
let drawerTitle = ref("");
// const props = defineProps(
//   {
//     drawerTitle: String,
//     default: () => "",
//   },
//   {
//     isShowDrawer: Boolean,
//     default: () => false,
//   }
// );
let drawerWidth = ref("300");
let max_domain = ref(0);
let purchasePeriod = reactive({}); // 购买年限列表
let placeOrderFormData = reactive({
  store_slug: "",
  periods: "",
  ordinary_san_num: 0,
  ip_san_num: 0,
  wildcard_san_num: 0,
  coupon: "",
});
let periods_price_txt = reactive([]);
let ordinary_san_num = ref(0);
let ip_san_num = ref(0);
let wildcard_san_num = ref(0);
let loading = ref(false);
// -----控制表单项显隐------
let isShowIp = ref(false);
let isShowOrdinary = ref(false);
let isShowSan = ref(false);
let periodsPrice = ref(0); // 年限单价
let canDecreaseOrdinary = ref(0);
let canDecreaseIP = ref(0);
let canDecreaseWildcard = ref(0);

let sum = ref(0);
const ruleInline = {
  periods: [{ required: true, message: "请选择购买年限", trigger: "blur" }],
};
// 打开弹窗
const openDrawer = (info) => {
  drawerTitle.value = info.store_name;
  isShowDrawer.value = true;
  periodsPrice.value = 0;
  proxy.$refs.placeOrderForm.resetFields();
  getProductDetails({ store_slug: info.store_slug });
};
// 关闭弹窗按钮
const closeDrawer = () => {
  proxy.$refs.placeOrderForm.resetFields();
  placeOrderFormData.periods = "";
  isShowDrawer.value = false;
  // 重置显示
  isShowIp.value = false;
  isShowSan.value = false;
  isShowOrdinary.value = false;
  periods_price_txt.length = 0;
  document.title = metaTags.title;
};
// 获取产品价格详情
const getProductDetails = (data) => {
  getProductDetailsApi(data).then((res) => {
    if (res.status == 200) {
      Object.assign(purchasePeriod, res.data.info);
      document.title = res.data.info.store_name;
      if (placeOrderFormData.ordinary_san_num == 0) {
        placeOrderFormData.ordinary_san_num = canDecreaseOrdinary.value;
      }
      max_domain.value = purchasePeriod.max_domain.toString();
      placeOrderFormData.store_slug = res.data.info.store_slug;
      placeOrderFormData.ssl_type = res.data.info.ssl_type;
      purchasePeriod.periods_price_txt.map((item) => {
        if (item.price > -1) {
          periods_price_txt.push(item);
        }
      });
      placeOrderFormData.periods = res.data.info.periods_price_txt[0].name;
      periodsPrice.value = res.data.info.periods_price_txt[0].price;
      showFormItem();
    }
  });
};

const orderPlace = (data) => {
  loading.value = true;
  orderPlaceApi(data)
    .then((res) => {
      if (res.status == 200) {
        loading.value = false;
        Message.warning("下单成功！将前往订单支付页面~");
        router.push({
          path: "/payment",
          query: {
            order_id: res.data.order_id,
          },
        });
      } else {
        loading.value = false;
        Message.error(res.msg);
      }
      console.log(res);
      document.title = metaTags.title;
    })
    .catch((err) => {
      loading.value = false;
      Message.error(err.msg);
    });
};
// 立刻下单按钮
const drawerCheckBtn = () => {
  proxy.$refs.placeOrderForm.validate((valid) => {
    if (valid) {
      let newData = {
        store_slug: placeOrderFormData.store_slug,
        periods: "",
        ordinary_san_num: placeOrderFormData.ordinary_san_num,
        ip_san_num: placeOrderFormData.ip_san_num,
        wildcard_san_num: placeOrderFormData.wildcard_san_num,
        coupon: placeOrderFormData.coupon,
      };
      if (placeOrderFormData.periods.length <= 3) {
        periods_price_txt.map((item) => {
          if (item.name == placeOrderFormData.periods) {
            newData.periods = item.name_un;
          }
        });
      } else {
        console.log("periods", placeOrderFormData.periods);
        newData.periods = placeOrderFormData.periods;
      }
      orderPlace(newData);
    } else {
      return false;
    }
  });
};
// 购买年限下拉框
const periodsSelect = (e) => {
  if (e != {}) {
    periods_price_txt.forEach((item) => {
      if (item.name == e.label) {
        placeOrderFormData.periods = item.name_un;
        periodsPrice.value =
          typeOf(item.price) == String ? Number(item.price) : item.price;
      }
    });
    // calculatePrice(placeOrderFormData)
  }
};
// 处理下单表单项的显隐
const showFormItem = () => {
  if (purchasePeriod.ssl_type != 2 && purchasePeriod.support_san != 0) {
    isShowIp.value = false;
    isShowOrdinary.value = false;
    isShowSan.value = false;
    // 混合证书处理
    if (purchasePeriod.ssl_type == 3) {
      isShowIp.value = true;
      isShowOrdinary.value = true;
      isShowSan.value = true;
      // 处理3个框的显隐，如果这里有改动，绑定的组件的判断也要做同步修改
      if (periods_price_txt != []) {
        isShowIp.value =
          purchasePeriod.san_price.ip[purchasePeriod.default] != "-1" ? true : false;
        isShowOrdinary.value =
          purchasePeriod.san_price.ordinary[purchasePeriod.default] != "-1"
            ? true
            : false;
        isShowSan.value =
          purchasePeriod.san_price.wildcard[purchasePeriod.default] != "-1"
            ? true
            : false;
      }
    }
    if (purchasePeriod.support_san == 1) {
      isShowOrdinary.value = true;
    }
    if (purchasePeriod.ssl_free == 1) {
      isShowOrdinary.value =
        purchasePeriod.san_price.ordinary[purchasePeriod.default] != -1 ? true : false;
    }
  }
};
const calculatePrice = (data) => {
  if (data.periods != "") {
    calculatePriceApi(data).then((res) => {
      if (res.status == 200) {
        sum.value = res.data.sum_price;
      }
    });
  }
};
// 处理混合域名的最小数量限制
const calculateMin = () => {
  let newData = {
    store_slug: placeOrderFormData.store_slug,
    periods: "",
    ordinary_san_num: placeOrderFormData.ordinary_san_num,
    ip_san_num: placeOrderFormData.ip_san_num,
    wildcard_san_num: placeOrderFormData.wildcard_san_num,
    coupon: placeOrderFormData.coupon,
  };
  periods_price_txt.forEach((item) => {
    if (item.name == placeOrderFormData.periods) {
      newData.periods = item.name_un;
      periodsPrice.value = typeOf(item.price) == String ? Number(item.price) : item.price;
    }
  });
  calculatePrice(newData);
};
// ===============处理网页标题，关键词，描述================
function getInitialMetaTags() {
  const title = document.title;
  const keywords = getMetaContent("keywords");
  const description = getMetaContent("description");

  return { title, keywords, description };
}

function getMetaContent(name) {
  const metaTag = document.querySelector(`meta[name="${name}"]`);
  return metaTag ? metaTag.getAttribute("content") : "";
}

function setMetaContent(name, content) {
  const metaTag = document.querySelector(`meta[name="${name}"]`);
  if (metaTag) {
    metaTag.setAttribute("content", content);
  } else {
    const meta = document.createElement("meta");
    meta.setAttribute("name", name);
    meta.setAttribute("content", content);
    document.head.appendChild(meta);
  }
}

watchEffect(() => {
  if (store.state.media.isDesktop == true) {
    drawerWidth.value = "480px";
  } else {
    drawerWidth.value = "100%";
  }
  if (placeOrderFormData.periods != "") {
    if (purchasePeriod.ssl_free == 1) {
      ordinary_san_num.value = purchasePeriod.san_price.ordinary.quarterly_price;
    } else {
      ordinary_san_num.value = purchasePeriod.san_price.ordinary.annually_price;
    }
    ip_san_num.value = purchasePeriod.san_price.ip.annually_price;
    wildcard_san_num.value = purchasePeriod.san_price.wildcard.annually_price;
  }
});

// 监听下单数据调用统计接口
watch(
  () => [
    placeOrderFormData.periods,
    placeOrderFormData.store_slug,
    placeOrderFormData.ordinary_san_num,
    placeOrderFormData.ip_san_num,
    placeOrderFormData.wildcard_san_num,
    placeOrderFormData.coupon,
  ],
  () => {
    calculateMin();
  },
  { deep: true }
);
defineExpose({
  openDrawer,
  closeDrawer,
  placeOrderForm: proxy.$refs.placeOrderForm,
});
onBeforeUnmount(() => {
  document.title = metaTags.title;
  setMetaContent("keywords", metaTags.keywords);
  setMetaContent("description", metaTags.description);
});
</script>

<style lang="less" scoped>
.periods {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 42px;
  .priceBox {
    display: flex;
    flex-direction: column;
    width: 60%;
    margin-left: 15px;
    justify-content: space-between;
    div {
      line-height: normal;
    }
    .tips {
      color: #8f8f8f;
    }
  }
}
.first {
  justify-content: space-between;
}
.drawer-footer {
  margin-top: 120px;
  .sum {
    text-align: right;
    margin-bottom: 10px;
    color: #000;
    span {
      font-size: 30px;
    }
  }
}
:deep(.ivu-input-disabled) {
  color: #000 !important;
}
:deep(.ivu-icon-ios-add:before),
:deep(.ivu-icon-ios-remove:before) {
  font-weight: 600 !important;
}
:deep(.ivu-drawer-header) {
  padding: 24px 16px !important;
}
:deep(.ivu-input-number-controls-outside .ivu-input-number-input) {
  text-align: center;
}
</style>
