<template>
  <div>
    <Modal
      v-model="showOrderingModal"
      :closable="false"
      :mask-closable="false"
      :loading="true"
      title="批量导出"
    >
      <Form class="form" ref="bulkExportForm" :model="bulkExportData" :label-width="70">
        <Row :gutter="24">
          <Col span="24">
            <FormItem label="产品：">
              <Select
                v-model="bulkExportData.bulk_export_product_id"
                @on-select="productSelect"
              >
                <Option
                  v-for="(item, index) in bulkExportParams"
                  :value="item.id"
                  :key="index"
                  :label="item.store_name"
                >
                </Option>
              </Select>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="24">
          <Col span="24">
            <FormItem label="下单时间">
              <Select
                v-model="bulkExportData.bulk_export_order_created_at_hour"
                @on-select="orderTimeSelect"
              >
                <Option
                  v-for="(time, ix) in buyTimeList"
                  :value="time.time"
                  :key="ix"
                  :label="time.time"
                >
                </Option>
              </Select>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="24">
          <Col span="24">
            <FormItem label="选择证书">
              <Select v-model="bulkExportData.bulk_export_certs" multiple>
                <Option
                  v-for="cert in certList"
                  :value="cert.id"
                  :key="cert.id"
                  :label="cert.common_name"
                >
                </Option>
              </Select>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="24">
          <Col span="24">
            <FormItem label="导文件名">
              <div class="exportBox">
                <Checkbox v-model="bulk_export_fullname"
                  >格式 “产品名字(下单时间) × 本数.zip”
                </Checkbox>
                <div class="selectAllBox">
                  <div class="tips">只支持导出批量下单的证书.</div>
                  <Button type="default" size="small" @click="selectAll">{{
                    selectChange
                  }}</Button>
                </div>
              </div>
            </FormItem>
          </Col>
        </Row>
      </Form>
      <template #footer>
        <div class="footerBtn">
          <Button type="text" size="large" @click="cancel">取消</Button>
          <Button
            type="primary"
            size="large"
            :loading="applyLoading"
            @click="submitForm('bulkExportForm')"
            >导出</Button
          >
        </div>
      </template>
    </Modal>
  </div>
</template>

<script setup>
import { ref, onMounted, reactive, getCurrentInstance } from "vue";
import { Message } from "view-ui-plus";
import { userProductParamsApi, bulkExportApi } from "@/api/personalCenter";
const { proxy } = getCurrentInstance(); // 获取组件实例
let companyList = reactive([]);
let showOrderingModal = ref(false);
let bulkExportData = reactive({
  bulk_export_product_id: "",
  bulk_export_order_created_at_hour: "",
  bulk_export_certs: "",
  bulk_export_fullname: "",
});
let selectChange = ref("全选");
let applyLoading = ref(false);
let bulk_export_fullname = ref(false);
let buyTimeList = reactive([]);
let certList = reactive([]);
let bulkExportParams = reactive([]);
const userProductParams = () => {
  userProductParamsApi().then((res) => {
    if (res.status == 200) {
      if (res.data && res.data.length > 0) {
        bulkExportParams.length = 0; // 清空数组
        bulkExportParams.push(...res.data);
      }
    } else {
      Message.error(res.msg);
    }
  });
};
const productSelect = (e) => {
  if (bulkExportParams.length > 0)
    bulkExportParams.forEach((item) => {
      if (item.id == e.value) {
        buyTimeList = item.bulk_time;
        if (buyTimeList.length > 0) {
          bulkExportData.bulk_export_order_created_at_hour = buyTimeList[0].time;
          buyTimeList.forEach((its) => {
            if (its.time == buyTimeList[0].time) {
              certList = its.domains;
            }
          });
        }
      }
    });
};
// 下单日期选择
const orderTimeSelect = (e) => {
  if (buyTimeList.length > 0) {
    buyTimeList.forEach((item) => {
      if (item.time == e.value) {
        certList = item.domains;
      }
    });
  }
};
// 全选方法
const selectAll = () => {
  if (bulkExportData.bulk_export_product_id == "") {
    Message.warning({
      content: "请先选择产品",
      duration: 3,
    });
  } else {
    // 将所有选项的值赋给 selectedItems 实现全选
    if (bulkExportData.bulk_export_certs.length < certList.length) {
      bulkExportData.bulk_export_certs = [];
      certList.map((item) => {
        bulkExportData.bulk_export_certs.push(item.id);
      });
      selectChange.value = "取消全选";
    } else {
      bulkExportData.bulk_export_certs = [];
      selectChange.value = "全选";
    }
  }
};
const submitForm = () => {
  proxy.$refs.bulkExportForm.validate((valid) => {
    if (valid) {
      bulkExportData.bulk_export_fullname =
        bulk_export_fullname.value == true ? "on" : "";
      bulkExportApi(bulkExportData)
        .then((res) => {
          if (res.status == 200) {
            window.location.href = `https://api.rjxssl.com/api/download?path=${res.data.zip_path}`;
            Message.success("导出成功");
            showOrderingModal.value = false;
          } else {
            showOrderingModal.value = false;
            Message.error(res.msg);
          }
        })
        .catch((res) => {
          Message.error({
            content: res.msg,
            duration: 3,
          });
          applyLoading = false;
        });
    } else {
      Message.error(res.msg);
    }
  });
};

const openModal = () => {
  showOrderingModal.value = true;
  bulk_export_fullname.value = false;
  selectChange.value = "全选";
  proxy.$refs.bulkExportForm.resetFields();
  userProductParams();
};
const cancel = () => {
  showOrderingModal.value = false;
  applyLoading = false;
};
defineOptions({
  name: "BulkExportModal",
});
defineExpose({
  openModal,
  cancel,
  bulkExportForm: proxy.$refs.bulkExportForm,
});
</script>
<style lang="less" scoped>
.exportBox {
  display: flex;
  flex-direction: column;
  .selectAllBox {
    display: flex;
    flex-direction: row;
    .tips {
      color: #777;
      font-size: 12px;
    }
  }
}
</style>
