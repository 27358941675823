<template>
  <div>
    <Exception type="404" img-color redirect="/" />
  </div>
</template>

<script setup></script>

<style lang="less" scoped>
:deep(.ivu-exception) {
  min-height: 700px !important;
  // padding-bottom: 50px !important;
}
</style>
