<template>
  <div class="order">
    <Tabs :value="tabActive" @on-click="changeTab">
      <TabPane
        v-for="(item, index) in tabList"
        :key="index"
        :label="item.label"
        :name="item.name"
      >
        <Space
          class="searchBox"
          :direction="$store.state.media.isMobile == true ? 'vertical' : 'horizontal'"
        >
          订单号：
          <Input
            v-model="pageData.order_id"
            placeholder="请输入订单号"
            :style="$store.state.media.isMobile == true ? 'width:100%' : 'width:300px'"
          />
          <div class="searchBarBtn">
            <Button
              type="primary"
              style="margin-right: 15px"
              icon="ios-search"
              @click="searchSubmit"
              >搜索</Button
            >
          </div>
        </Space>
        <Divider />
        <div class="list" v-if="orderList.length != 0">
          <div v-if="$store.state.media.isDesktop == true" class="Pc">
            <div class="list-item" v-for="order in orderList" :key="order.id">
              <div class="item-top">
                <div class="top-left">订单日期：{{ order.add_time }}</div>
                <div class="top-right" v-if="order.status == 0" style="color: blue">
                  已下单
                </div>
                <div
                  class="top-right"
                  style="color: LimeGreen"
                  v-else-if="order.status == 1 && order.refund_status == 0"
                >
                  已支付
                  <span style="color: #aaa">({{ order.pay_type_text }})</span>
                </div>
                <div
                  class="top-right"
                  v-if="order.status == 2 && order.refund_status == 0"
                  style="color: Teal"
                >
                  已申请
                </div>
                <div class="top-right" style="color: red" v-else-if="order.status == -1">
                  已取消
                </div>
                <div
                  class="top-right"
                  style="color: orange"
                  v-else-if="order.refund_status == 2"
                >
                  已退款
                </div>
              </div>
              <div class="item-center">
                <div class="center-left">
                  <img :src="order.brand.image" alt="" />
                </div>
                <div class="center-right">
                  <div class="right_top">
                    <div class="right_top_left">{{ order.product.store_name }}</div>
                    <div class="right_top_right" v-if="order.san">
                      SAN x{{ order.san }}
                    </div>
                    <div class="right_top_right" v-else>无追加 SAN</div>
                  </div>
                  <div class="right_bottom">
                    <div class="right_bottom-left">{{ order.periods_text }}</div>
                    <div class="right_bottom-right">订单号：{{ order.order_id }}</div>
                  </div>
                </div>
              </div>
              <Divider :dashed="true" />
              <div class="item-bottom">
                <div class="bottom-left">￥{{ order.pay_price }}</div>
                <div class="bottom-right">
                  <Button
                    style="margin-right: 15px"
                    v-if="order.status == 1"
                    @click="applyRefund(order.order_id)"
                    >申请退款</Button
                  >

                  <Button
                    type="primary"
                    class="bottom-right"
                    @click="toDetail(order.order_id)"
                  >
                    查看详情
                  </Button>
                </div>
              </div>
              <Divider />
            </div>
          </div>
          <div v-else class="notPc">
            <div class="list-item" v-for="order in orderList" :key="order.id">
              <div class="item-top">
                <div class="time">订单日期：{{ order.add_time }}</div>
                <div class="top-right" v-if="order.status == 0" style="color: blue">
                  已下单
                </div>
                <div
                  class="top-right"
                  style="color: green"
                  v-else-if="order.status == 1 && order.refund_status == 0"
                >
                  已支付
                  <span style="color: #aaa">({{ order.pay_type_text }})</span>
                </div>
                <div
                  class="top-right"
                  v-if="order.status == 2 && order.refund_status == 0"
                  style="color: black"
                >
                  已申请
                </div>
                <div class="top-right" style="color: red" v-else-if="order.status == -1">
                  已取消
                </div>
                <div
                  class="top-right"
                  style="color: orange"
                  v-else-if="order.refund_status == 2"
                >
                  已退款
                </div>
              </div>
              <div class="item-center">
                <div class="center-top">
                  <!-- <img src="@/assets/images/DV.png" alt="" /> -->
                  <img :src="order.brand.image" alt="" />
                </div>
                <div class="center-content">
                  <div class="content-title">{{ order.product.store_name }}</div>
                </div>
                <div class="center-bottom">
                  <div class="bottom-item">购买时限：{{ order.periods_text }}</div>
                  <div class="bottom-item" v-if="order.san">SAN x{{ order.san }}</div>
                  <div class="bottom-item" v-else>无追加 SAN</div>
                  <div class="bottom-item">订单号：{{ order.order_id }}</div>
                  <div class="bottom-item">
                    <div class="price">￥{{ order.pay_price }}</div>
                  </div>
                </div>
              </div>
              <Divider :dashed="true" />
              <div class="item-bottom">
                <Button
                  style="margin-right: 15px"
                  v-if="order.status == 1 && order.refund_status == 0"
                  @click="applyRefund(order.order_id)"
                  >申请退款</Button
                >
                <Button
                  type="primary"
                  class="bottom-right"
                  @click="toDetail(order.order_id)"
                >
                  查看详情
                </Button>
              </div>
              <Divider />
            </div>
          </div>
          <Page
            :total="total"
            :v-model="pageData.page"
            :page-size="pageData.limit"
            show-total
            class="pageBar"
            @on-change="pageChange"
          />
        </div>
        <div class="empty" v-else>
          <div class="emptyImg">
            <img src="@/assets/images/list_empty.jpg" alt="" />
          </div>
        </div>
      </TabPane>
    </Tabs>
  </div>
</template>

<script setup>
import { orderApi } from "@/api/personalCenter";
import { applyRefundApi } from "@/api/order";
import { ref, reactive, onMounted } from "vue";
import { useRouter } from "vue-router";
import { Message, Modal } from "view-ui-plus";

let router = useRouter();
// 分页器
const total = ref(0);
const pageData = reactive({
  page: 1,
  limit: 15,
  status: "",
});
const serchValue = ref("");
const tabActive = ref("1");
const tabList = reactive([
  {
    label: "全部明细",
    name: "",
  },
  {
    label: "已下单",
    name: "0",
  },
  {
    label: "已支付",
    name: "1",
  },
  {
    label: "已申请",
    name: "2",
  },
  {
    label: "已取消",
    name: "-1",
  },
]);
let orderList = reactive([]);
function getList(data) {
  orderApi(data).then((res) => {
    if (res.status === 200) {
      total.value = res.data.count;
      orderList.length = 0; // 清空数组
      orderList.push(...res.data.list); // 添加新数据到数组
    }
  });
}
const searchSubmit = () => {
  pageData.page = 1;
  pageData.limit = 15;
  getList(pageData);
};
const applyRefund = (id) => {
  Modal.confirm({
    title: "退款确认",
    content:
      "您的订单将退款, 已签发的证书将在1到7日内吊销不能使用,资金将于7日内到达您的余额账户, 是否继续？",
    loading: true,
    onOk() {
      applyRefundApi({ order_id: id })
        .then((res) => {
          if (res.status == 200) {
            Message.success("退款成功");
            setTimeout(() => {
              Modal.remove();
              getList(pageData);
            }, 200);
          } else {
            Message.error(res.msg);
          }
        })
        .catch((res) => {
          Modal.remove();
          Message.error(res.msg);
        });
    },
  });
};
// 切换数据类型
function changeTab(name) {
  if (name == "") {
    pageData.status = "";
  } else {
    pageData.status = name;
  }
  pageData.page = 1;
  getList(pageData);
}

// 分页器切换
const pageChange = (e) => {
  pageData.page = e;
  getList(pageData);
};

const toDetail = (id) => {
  router.push({
    path: "/orderDetail",
    query: {
      id: id,
    },
  });
};
onMounted(() => {
  getList(pageData);
});
</script>

<style lang="less" scoped>
.order {
  min-height: 700px;
  background-color: #fff;
  .list {
    padding: 15px;
    .Pc {
      .list-item {
        .item-top {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          font-size: 16px;
          font-family: SourceHanSansSC-regular;
          .top-left {
            color: #000;
            font-weight: 600;
          }
        }
        .item-center {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          color: #999;
          font-size: 16px;
          font-family: SourceHanSansSC-regular;
          margin: 30px 0;
          .center-left {
            width: 213px;
            height: 40px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .center-right {
            margin-left: 40px;
            width: 100%;
            .right_top,
            .right_bottom {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
            }
            .right_top_left {
              color: #000;
              font-weight: 600;
            }
          }
        }
        .item-bottom {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          .bottom-left {
            color: red;
            font-size: 18px;
            line-height: 1.5;
            height: 40px;
            line-height: 40px;
          }
          Button {
            width: 120px;
            height: 38px;
          }
        }
      }
      :deep(.ivu-tabs-nav-container) {
        font-size: 16px !important;
      }
    }
    .notPc {
      .list-item {
        .item-top {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          font-size: 14px;
          font-family: SourceHanSansSC-regular;
          .top-left {
            color: #000;
            font-weight: 600;
          }
        }
        .item-center {
          width: 100%;
          display: flex;
          flex-direction: column;
          // justify-content: flex-start;
          align-items: center;
          color: #999;
          font-size: 16px;
          font-family: SourceHanSansSC-regular;
          margin: 30px 0 15px;
          .center-top {
            width: 218px;
            height: 40px;
            display: flex;
            align-items: center;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .center-content {
            margin: 15px 10px;
            width: 100%;
            .content-title {
              color: #000;
              font-weight: 600;
              text-align: center;
            }
          }
          .center-bottom {
            width: 100%;
            text-align: right;
            font-size: 14px;
          }
          .price {
            color: red;
            font-size: 18px;
            line-height: 1.5;
            height: 40px;
            line-height: 40px;
          }
        }
        .item-bottom {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-around;

          Button {
            width: 120px;
            height: 38px;
          }
        }
      }
    }
  }
  .searchBox {
    padding: 10px 15px;
    width: 100%;
  }
  .pageBar {
    text-align: right;
    margin-top: 50px;
  }
}
.empty {
  min-height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
  .emptyImg {
    width: 360px;
    height: 275px;
  }
  img {
    width: 100%;
    height: 100%;
  }
}
:deep(.ivu-tabs-nav),
:deep(.ivu-space-item) {
  font-size: 16px !important;
}
:deep(.ivu-tabs-nav-scrollable) {
  padding: 0 10px;
}
:deep(.ivu-tabs-nav-prev) {
  display: none !important;
}
:deep(.ivu-tabs-nav-next) {
  display: none !important;
}

:deep(.ivu-tabs-nav-scroll) {
  overflow: scroll !important;
}
:deep(.ivu-tabs-nav-scroll::-webkit-scrollbar) {
  height: 0;
}
</style>
